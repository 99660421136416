<template>
    <div>
        <b-card no-body class="mb-1">
            <b-card-header>
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button
                    class="mr-1"
                    @click="$store.commit('Set_filter_Dto', localeFilterDto)"
                    variant="primary"
                    >فلترة</b-button
                >
                <b-button @click="resetFilterDto()" variant="secondary"
                    >تهيئة</b-button
                >
            </b-card-header>
            <b-card-body class="pb-1">
                <b-row>
                    <b-col cols="12" md="6">
                        <EKInputSelect
                            label="المادة"
                            placeholder="اختر المادة "
                            :options="[
                                { id: 0, name: 'الكل' },
                                ...subjectsList
                            ]"
                            name="select"
                            :clearable="true"
                            v-model="localeFilterDto.subjectId"
                        />
                    </b-col>
                    <b-col cols="12" md="6">
                        <EKInputSelect
                            label="سنة الدورة"
                            placeholder="اختر السنة "
                            :options="[{ id: 0, name: 'الكل' }, ...years]"
                            name="year"
                            :clearable="true"
                            v-model="localeFilterDto.examYear"
                        />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <EKTable
            :items="lectureList"
            :columns="columns"
            @details="openCourcesDetails"
            @delete-selected="fireDeleteEvent"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ new Date(value).toLocaleDateString("en-GB") }}
            </template>

            <template slot="items.download" slot-scope="{ props }">
                <b-button
                    size="sm"
                    variant="info"
                    class="btn-icon rounded-circle"
                    @click="downloadItem(props)"
                >
                    <unicon
                        name="download-alt"
                        fill="#fff"
                        height="18"
                        width="18"
                    ></unicon>
                </b-button>
            </template>

            <template slot="items.delete" slot-scope="{ props }">
                <b-button
                    size="sm"
                    variant="danger"
                    class="btn-icon rounded-circle"
                    @click="deleteLecture(props.row.id)"
                >
                    <unicon
                        name="trash-alt"
                        fill="#fff"
                        height="18"
                        width="18"
                    ></unicon>
                </b-button>
            </template>
        </EKTable>
    </div>
</template>
<script>
import EKTable from "@Ekcore/components/EK-table";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import { mapActions, mapState, mapGetters } from "vuex";
import axios from "axios";
export default {
    components: {
        EKTable,
        EKInputSelect
    },
    computed: {
        ...mapState({
            faculties: state => state.faculties.faculties,
            filterDto: state => state.filter.filterDto,
            subjectsList: state => state.subjects.subjectsList
        }),
        ...mapGetters(["lectureList", "years"])
    },
    data: () => ({
        localeFilterDto: {
            subjectId: 0,
            examYear: 0,
            semesterId: 0,
            facultyId: 0
        },
        columns: [
            {
                label: "اسم المحاضرة",
                field: "name"
            },
            {
                label: "اسم المادة",
                field: "subjectName",
                sortable: false
            },
            {
                label: "تاريخ الإضافة",
                field: "dateCreated",
                sortable: false
            },
            {
                label: "تحميل",
                field: "download",
                sortable: false
            },
            {
                label: "حذف",
                field: "delete",
                sortable: false
            }
        ]
    }),

    created() {
        this.getLectureList();
        this.getFacultiesDetails();
        this.fetchTotalTag();
        this.fetchSubject({ semesterId: "", year: "", facultyId: "" });
    },
    methods: {
        ...mapActions([
            "getLectureList",
            "getFacultiesDetails",
            "fetchTotalTag",
            "fetchSubject",
            "deleteLectureList",
            "deleteLecture"
        ]),
        openCourcesDetails(props) {
            this.$router.push("/courses/" + props.row.id);
        },
        fireDeleteEvent(list) {
            this.deleteLectureList(list);
        },
        resetFilterDto() {
            Object.assign(this.localeFilterDto, {
                subjectId: 0,
                examYear: 0,
                semesterId: 0,
                facultyId: 0
            });
        },
        downloadItem(props) {
            axios
                .get(
                    (
                        this.$store.getters["app/domainHost"] +
                        "/" +
                        props.row.examDocumentPaths[0]
                    ).replace(/%(?![0-9A-F]{2})/g, "%25"),
                    { responseType: "blob" }
                )
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = props.row.name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch(console.error);
        }
    },
    beforeDestroy() {
        this.$store.commit("Reset_filter_Dto");
        this.$store.commit("Reset_Search_Dto");
    }
};
</script>
